<template>
  <div class="withdraw">
    <div class="navBar">
      <div @click="backPage">
        <svg-icon class="navArrow" iconClass="navArrow" />
      </div>
      <source />
      <div class="navTitle">收益提现</div>
      <router-link tag="div" to="withdrawalDetails" class="rightBtn"
        >提现明细</router-link
      >
    </div>
    <div class="main">
      <div class="walletBox">
        <div class="walletTitle">
          <div class="walletLine"></div>
          收益金豆
        </div>
        <div class="walletAmount">{{ walletInfo.income }}</div>
      </div>
      <div class="cellItem">
        <div class="cellTitle">提现币类:</div>
        <div class="cellContent amountType">人民币</div>
      </div>
      <div class="cellItem">
        <div class="cellTitle">提现金额:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="withdrawAmount"
          :border="false"
          :placeholder="`可提现${walletInfo.income}金豆/${
            walletInfo.income ? (walletInfo.income / 10).toFixed(1) : '0.0'
          }元`"
          @input="changeAmount"
          @blur="changBlur"
        ></van-field>
      </div>
      <div class="cellItem">
        <div class="cellTitle">提现方式:</div>
        <van-radio-group
          v-model="withdrawTypeIndex"
          direction="horizontal"
          class="cellContent radioGroup"
        >
          <div
            class="radioItem"
            v-for="(item, index) in withdrawTypes"
            :key="index"
            @click="changeWIthdrawType(index)"
          >
            <svg-icon class="withdrawIcon" :iconClass="item.payType"></svg-icon>
            <div>{{ formatWithdrawType(item.payType) }}</div>
            <van-radio
              checked-color="#FC4162"
              icon-size="16px"
              :name="index"
            ></van-radio>
          </div>
          <!-- <div class="radioItem">
                    <svg-icon class="withdrawIcon" iconClass="alipay"></svg-icon>
                    <div>支付宝</div>
                    <van-radio checked-color="#fe7f0f" icon-size="16px" name="alipay"></van-radio>
                </div> -->
        </van-radio-group>
      </div>
      <!-- 支付宝 -->
      <div class="cellItem" v-if="withdrawType == 'alipay'">
        <div class="cellTitle">姓名:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="actName"
          :border="false"
          placeholder="请输入支付宝姓名"
        ></van-field>
      </div>
      <div class="cellItem" v-if="withdrawType == 'alipay'">
        <div class="cellTitle">支付宝:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="act"
          :border="false"
          placeholder="请输入支付宝账号"
        ></van-field>
      </div>
      <!-- 银行卡 -->
      <div class="cellItem" v-if="withdrawType == 'bankcard'">
        <div class="cellTitle">银行卡:</div>
        <router-link tag="div" to="bankCardList" class="cellContent setBankcard">
          <div v-if="bankCard.act">{{ getBankName(bankCard.bankCode) }}({{ bankCard.act.slice(-4) }})</div>
          <div v-else>请选择银行账户</div>
          <svg-icon class="rightArrow" iconClass="rightArrow"></svg-icon>
        </router-link>
        <!-- <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="actName"
          :border="false"
          placeholder="请输入开户姓名"
        ></van-field> -->
      </div>
      
      <div class="cellItem" v-if="withdrawType == 'alipay'">
        <div class="cellTitle">姓名:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="actName"
          :border="false"
          placeholder="请输入姓名"
        >
        </van-field>
      </div>
      <div class="cellItem" v-if="withdrawType == 'alipay'">
        <div class="cellTitle">支付宝:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="act"
          :border="false"
          placeholder="请输入银行卡号"
          @blur="checkBankCard"
        >
        </van-field>
      </div>
      <!-- USDT -->
      <div class="cellItem" v-if="withdrawType == 'usdt'">
        <div class="cellTitle">钱包地址:</div>
        <van-field
          class="cellContent vantInput"
          :center="true"
          v-model="act"
          :border="false"
          placeholder="请输入USDT钱包地址"
        >
        </van-field>
      </div>
      <!-- <div class="cellItem" v-if="withdrawType == 'usdt'">
                <div class="cellTitle">温馨提示:</div>
                <div class="cellContent handlingFee">请您务必仔细核对收款地址信息后再做提交，避免给您带来不便。</div>
            </div> -->
      <div class="cellItem">
        <div class="cellTitle">手续费率:</div>
        <div class="cellContent handlingFee">
          {{ `${handlingFee}%` }}
          <div class="cellTipBox">
            本次提现手续: {{ formalitiesAmount }} 实际到账金额: {{ realAmount }}
          </div>
        </div>
      </div>
      <div class="ruleTitle">提现规则</div>
      <div class="ruleContent">
        1.收款账户卡号和姓名必须一致,为避免恶意刷单,到账时间为T+15天 <br />
        2.银行卡每次提现最低200元起,USDT每次提现最低2000元起
      </div>
    </div>
    <div class="buttonBox">
      <div class="withdrawBtn" @click="withdraw">立即提现</div>
      <div class="kfTip">
        提现中如有问题，请咨询
        <router-link tag="span" to="/customerService">在线客服</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { queryWithdrawCfg, withdraw, checkBankCard } from "@/api/mine";
import { mapGetters } from "vuex";
import { getBank } from "@/utils/bankCardList";
import {
  Field,
  // Loading,
  RadioGroup,
  Radio,
  Toast,
} from "vant";
export default {
  name: "withdraw",
  components: {
    [Field.name]: Field,
    // [Loading.name]: Loading,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  computed: {
    ...mapGetters({
      bankCard: "bankCard",
    }),
  },
  data() {
    return {
      walletInfo: {},
      withdrawType: "",
      withdrawTypeIndex: 0, // 提现方式下标
      withdrawAmount: "",
      act: "", // 交易账户
      actName: "", // 交易账户持有人
      bankCode: "", // 银行卡编号
      handlingFee: 0, // 手续费
      formalitiesAmount: 0, //手续费金额
      realAmount: 0, // 实际到账金额
      // cid: "", // 渠道ID
      withdrawTypes: [], // 提现类型列表
      minMoney: 0, // 单笔提现最小金额
      maxMoney: 0, // 单笔提现最大金额
      withdrawPlaceholder: "单笔提现金额范围: 1-100元", //提现提示
      bankDatas: getBank(), // 银行列表
    };
  },
  created() {
    this.queryBalance();
    this.queryWithdrawCfg();
  },
  methods: {
    changBlur() {
      window.scroll(0, 0); //失焦后强制让页面归位
    },
    // 返回
    backPage() {
      this.$router.go(-1);
    },
    getBankName(bankCode) {
      let str = "";
      this.bankDatas.forEach((i) => {
        if (i.value == bankCode) {
          str = i.text;
        }
      });
      return str;
    },
    // 查询钱包
    async queryBalance() {
      await this.$store.dispatch("user/getWallet");
      this.walletInfo = this.$store.getters.walletInfo;
    },
    // 查询提现配置
    async queryWithdrawCfg() {
      this.$store.commit("app/SET_LOADING", true);
      // try {
      let res = await this.$Api(queryWithdrawCfg);
      // console.log(res)
      this.$store.commit("app/SET_LOADING", false);
      if (res && res.code == 200) {
        let arr = res.data.channels || [];
        this.withdrawTypes = arr.filter((i) => {
          return i.payType == "bankcard";
        });
        // console.log(this.withdrawType)
        // console.log(newArr, "--------")
        // this.withdrawTypes = res.data.channels;
        let usdtType = {
          channelName: "",
          maxMoney: "",
          minMoney: "",
          payType: "usdt",
        };
        this.withdrawTypes.push(usdtType);
        this.handlingFee = res.data.coinTax;
        this.changeWIthdrawType(0);
      }
      // } catch (error) {
      //     this.$store.commit('app/SET_LOADING', false);
      //     Toast('查询失败，请重新进入');
      // }
    },
    // 计算金额
    changeAmount() {
      let money = this.balance / 10;
      if (this.withdrawAmount > money) {
        Toast("提现金额大于自身拥有金额");
        // return;
      }
      this.formalitiesAmount = (
        (this.withdrawAmount * this.handlingFee) /
        100
      ).toFixed(2);
      this.realAmount = (this.withdrawAmount - this.formalitiesAmount).toFixed(
        2
      );
    },
    // 改变提现方式
    changeWIthdrawType(index) {
      this.act = "";
      this.actName = "";
      let typeList = this.withdrawTypes;
      this.withdrawTypeIndex = index;
      // let typeIndex = this.withdrawTypeIndex;
      // this.cid = typeList[index].cid;
      this.withdrawType = typeList[index].payType;
      if (this.withdrawType.payType != "usdt") {
        this.minMoney = typeList[index].minMoney / 100;
        this.maxMoney = typeList[index].maxMoney / 100;
      }
    },
    // 提现类型名称
    formatWithdrawType(type) {
      let str = "";
      switch (type) {
        case "alipay":
          str = "支付宝";
          break;
        case "bankcard":
          str = "银行卡";
          break;
        case "usdt":
          str = "USDT";
          break;
        default:
          break;
      }
      return str;
    },
    // 校验银行卡
    async checkBankCard() {
      let req = {
        _input_charset: "utf-8",
        cardBinCheck: true,
        cardNo: this.act,
      };
      if (!this.act) return;
      this.$store.commit("app/SET_LOADING", true);
      try {
        let res = await this.$Api(checkBankCard, req);
        this.$store.commit("app/SET_LOADING", false);
        if (res && res.validated && res.bank && res.cardType) {
          this.bankCode = res.bank;
        } else {
          this.bankCode = "";
          Toast("请输入正确的银行卡号");
        }
      } catch (error) {
        this.$store.commit("app/SET_LOADING", false);
        Toast("网络错误，请稍后再试");
      }
    },
    // 提现
    async withdraw() {
      let userInfo = this.$store.getters.userInfo;
      let money = this.balance / 10;

      if (!this.withdrawAmount) {
        Toast("请输入提现金额");
        return;
      }

      if (this.withdrawAmount > money) {
        Toast("提现金额大于自身拥有金额");
        return;
      }
      if (this.withdrawAmount > this.maxMoney && this.withdrawType != "usdt") {
        Toast(`单笔提现金额不大于${this.maxMoney}`);
        return;
      }
      if (this.withdrawAmount < this.minMoney && this.withdrawType != "usdt") {
        Toast(`单笔提现金额不小于${this.minMoney}元`);
        return;
      }
    //   if (this.withdrawType != "usdt") {
    //     console.log()
    //   }
      if (this.withdrawType == 'bankcard' && !this.bankCard.bankCode) {
        Toast("请输选择银行卡");
        return;
      }
      if (!this.actName && this.withdrawType == "alipay") {
        Toast("请输入姓名");
        return;
      }
      if (!this.act && this.withdrawType == "alipay") {
        Toast("请输入支付宝");
        return;
      }
    //   if (this.withdrawType == "bankcard" && !this.bankCode) {
    //     Toast("请输入正确的银行卡号");
    //     return;
    //   }

      // if (this.withdrawType == "bankcard" && this.checkBankCard()) {
      //     Toast('请输入正确的银行卡号')
      //     return;
      // }

      let req = {
        name: userInfo.name, // 用户名
        money: this.withdrawAmount * 100, // 分
        payType: this.withdrawType, // alipay，bankcard，usdt
        // withdrawType: 1, // 0: 代理提现   1: 金币提现
        actName: this.actName, // 提现姓名
        bankCode: this.bankCode, // 银行卡Code
        act: this.act, // 提现账号
        // cid: this.cid, // 渠道ID
        devID: this.$store.getters.UID,
        productType: 0, // 0: 站群  1: 棋牌
      };
      this.$store.commit("app/SET_LOADING", true);
      try {
        let ret = await this.$Api(withdraw, req);
        this.$store.commit("app/SET_LOADING", false);
        if (ret && ret.code === 200) {
          this.queryBalance();
          Toast("已提交申请");
          this.withdrawAmount = "";
        } else if (ret.tip) {
          Toast(ret.tip || "提现失败");
        }
      } catch (error) {
        Toast("操作失败");
        this.$store.commit("app/SET_LOADING", false);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.withdraw {
  height: 100%;
  color: #000;
  background: #f5f5f5;

  .navBar {
    height: 44px;
    padding: 0 12px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .navArrow {
      width: 17px;
      height: 17px;
      margin-right: 47px;
    }

    .navTitle {
      flex: 2;
      text-align: center;
      font-size: 18px;
      color: #000;
    }

    .rightBtn {
      width: 64px;
      font-size: 15px;
      color: #999999;
    }
  }

  .main {
    height: calc(100% - 45px);
    overflow-y: auto;
    padding: 12px 16px 0;
    box-sizing: border-box;

    .walletBox {
      width: 100%;
      height: 102px;
      border-radius: 8px;
      overflow: hidden;
      background: #fff;

      .walletTitle {
        font-size: 12px;
        color: #94d6da;
        display: flex;
        align-items: center;
        margin: 12px 0 7px;
        .walletLine {
          width: 8px;
          height: 22px;
          border-radius: 0 6px 6px 0;
          background: #94d6da;
          margin-right: 12px;
        }
      }

      .walletAmount {
        font-size: 32px;
        margin-left: 20px;
        font-weight: 500;
        color: #94d6da;
      }
    }

    .cellItem {
      width: 100%;
      height: 49px;
      // padding: 0 12px;
      // box-sizing: border-box;
      display: flex;
      align-items: center;

      .cellTitle {
        // flex: 1;
        font-size: 14px;
        margin-right: 15px;
        width: 60px;
      }

      .cellContent {
        flex: 2;
      }
      .setBankcard {
        width: 100%;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        box-sizing: border-box;
        color: #999;
        background: #e5e5e5;
      }

      .amountType {
        font-size: 14px;
        color: #333;
      }

      .handlingFee {
        font-size: 11px;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .vantInput {
        height: 36px;
        // border-radius: 33px;
        background: #e5e5e5;
        padding-left: 12px;
        padding-right: 12px;

        /deep/ .van-field__control {
          font-size: 14px;
          color: #000;
        }

        /deep/ .van-field__control {
          font-size: 14px;
        }

        ::placeholder {
          color: #999999;
        }
      }

      .radioGroup {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 16px;
        // grid-column-gap: 6px;

        .radioItem {
          display: flex;
          align-items: center;
          justify-content: space-around;
          flex: 1;
          font-size: 16px;

          .withdrawIcon {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
    .ruleTitle {
      font-size: 14px;
    }
    .ruleContent {
      font-size: 12px;
      margin-top: 13px;
      color: #999;
    }
  }
  .buttonBox {
    position: fixed;
    bottom: 34px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .kfTip {
      font-size: 12px;
      color: #666;
      span {
        color: #94d6da;
      }
    }
    .withdrawBtn {
      width: 297px;
      height: 45px;
      line-height: 45px;
      border-radius: 45px;
      font-size: 18px;
      margin-bottom: 12px;
      color: #fff;
      background: #94d6da;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
